import { HttpStatusCode } from "@solidjs/start";
import type { JSX } from "solid-js";

import styles from "~/components/Post.module.scss";
import Title from "~/components/Title";

const messages: JSX.Element[] = [
  "Non ecziste!!!",
  "Você perdeu alguma coisa?",
  "Seus números de sorte: quatro, zero, quatro",
  "Esta página tomou Doril",
  <i lang="la">Exorcizamus te, omnis immundus spiritus…</i>,
  <i lang="en">
    <abbr title="Missing In Action">M.I.A.</abbr>
  </i>,
  "Se há algo aqui, só bolas de feno",
  "Você ganhou R$ 1.000.000,00, só que não",
  "Um buraco no meio e um vazio dentro",
  <i lang="la">CDIV non inventus</i>,
  "Minha voz ecoa aqui!",
  "Até o silêncio faz barulho",
  "Vê algo? Nem eu",
  "Era uma página muito engraçada…",
  "A Página Nova do Imperador",
  "Onde está a página? O gato comeu",
  "Mais fácil encontrar Carmen Sandiego",
  "Trazemos sua página amada em três dias!",
  <i lang="la">Revertere ad locum tuum</i>,
  "Aqui dá aonde o Kira foi parar… ou não",
];

export default function NotFound() {
  const random = Math.floor(Math.random() * messages.length);

  return (
    <main>
      <Title>Página não encontrada</Title>
      <HttpStatusCode code={404} />
      <article class={styles.article}>
        <h1>{messages[random]}</h1>
        <div class={styles.body}>
          <p>
            Desculpe, mas parece que a página que tentou acessar não existe.
            Provavelmente seguiu um link externo errado ou desatualizado,
            digitou errado uma URL ou ainda tentou acessar uma pasta, página ou
            arquivo restrito.
          </p>
          <p>
            Tente encontrar a página que procura pelo menu principal e/ou pela
            busca ou verifique se você, por acidente, não tenha digitado a URL
            de forma errada.
          </p>
        </div>
      </article>
    </main>
  );
}
